class Menu {

	static createAsLiLinks( menuItems ) {
	  const result = [];
	  for( let item of menuItems ) {
	    result.push( '<li>' );
	    result.push( `<a href="${item.href}">` );
	    result.push( item.label );
	    result.push( '</a>' );
	    result.push( '</li>' );
	  }
	  return result.join( '\n' );
	};

	static createLinksWithCurrentText = ( currentName, menuItems ) => {
	  const result = [];
	  for( let menuItem of menuItems ) {
	    if( currentName === menuItem.name ) {
	      result.push( `<p class="navbar-item">${menuItem.label}</p>` );
	    } else {
	      result.push( `<a href="${menuItem.href}" class="navbar-item">${menuItem.label}</a>` );
	    }
	  }
	  return result.join( '\n' );
	};
}
export default Menu;
