import React from 'react';

const establishedYear = 2019;
const getYearMark = () => {
	const currentYear = new Date().getFullYear();
	return currentYear === establishedYear
		? currentYear : `${establishedYear} - ${currentYear}`
};


const copyright = `(c) ${getYearMark()} gleanbean Co., Ltd. All Rights Reserved.`;

const Footer = () => (
  <footer className="footer has-background-grey-dark">
    <div className="hero is-small">
      <div className="hero-body has-text-centered has-text-white">
        <span>{copyright}</span>
      </div>
    </div>
  </footer>
);

export default Footer;
