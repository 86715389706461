
const Config = {
  html: {
    lang: 'ja'
  },
  header: {
    title: '株式会社gleanbean',
    meta: [
        {
          name: 'description',
          content: '株式会社gleanbeanをご紹介します。'
        },
        {
          name: 'keywords',
          content: '株式会社gleanbean,gleanbean Co. Ltd.'
        }
    ]
  },

  logo: {
    text: 'gleanbean Co., Ltd.',
    fontColor: '#333',
    backgroundColor: 'white',
    lineColor: '#23F52A',
    href: '/'
  },

  menu: [
    {
      name: 'news',
      label: 'ニュース',
      href: '/news'
    },
    {
      name: 'overview',
      label: '会社概要',
      href: '/overview'
    },
    {
      name: 'business',
      label: '事業内容',
      href: '/business'
    },
    {
      name: 'philosophy',
      label: '企業理念',
      href: '/philosophy'
    },
    {
      name: 'careers',
      label: '採用情報',
      href: '/careers'
    },
    {
      name: 'contact_us',
      label: 'お問い合わせ',
      href: '/contact_us'
    }
  ],

  mail: {
    company: 'contact-us@gleanbean.co.jp',
    sesDivision: 'contact-us@gleanbean.jp'
  }
};
export default Config;
